<template>
  <div class="screen-box">
    <div class="mapclass">
      <EchartChina ref="mapChina"></EchartChina>
      <Echart3D ref="Echart3D"></Echart3D>
    </div>
    <div class="screen-head">
      <div class="slide-box"></div>
      <div class="head-center">{{ title }}</div>
      <div class="slide-box head-title">
        {{ moment().format("YYYY-MM-DD HH:mm:ss") }} UTC+08:00
      </div>
    </div>
    <div class="mt6">
      <ul class="flex-box">
        <li>
          <div class="item-top">
            <span>{{ stationInfoData.stationCount }}</span>
            <span>个</span>
          </div>
          <div class="item-btm">电站数量</div>
        </li>
        <li>
          <div class="item-top">
            <span>{{ stationInfoData.totalCapacity }}</span>
            <span>kMp</span>
          </div>
          <div class="item-btm">总容量</div>
        </li>
        <li>
          <div class="item-top">
            <span>{{ (stationInfoData.totalActivePower/1000).toFixed(2) }}</span>
            <span>KW</span>
          </div>
          <div class="item-btm">发电功率</div>
        </li>
        <li>
          <div class="item-top">
            <span>{{ stationInfoData.totalDayPower }}</span>
            <span>kWh</span>
          </div>
          <div class="item-btm">当日发电量</div>
        </li>
        <li>
          <div class="item-top">
            <span>{{ stationInfoData.totalMonthPower }}</span>
            <span>kWh</span>
          </div>
          <div class="item-btm">当月发电量</div>
        </li>
        <li>
          <div class="item-top">
            <span>{{ stationInfoData.totalYearPower }}</span>
            <span>kWh</span>
          </div>
          <div class="item-btm">当年发电量</div>
        </li>
      </ul>
    </div>
    <div class="layer-left layer-box">
      <!-- 暂无 -->
      <!-- <div class="layer-one">
        <div class="left-head left-flex">
          <div class="title">装机容量统计</div>
        </div>
        <div class="layer-content">
          <div id="capacity" style="width: 100%; height: 100%"></div>
        </div>
      </div> -->
      <div class="layer-one">
        <div class="left-head">
          <div class="title">电站满发小时排名</div>
        </div>
        <div class="layer-content padding-all">
          <a-empty v-if="hourData.length == 0" description="暂无数据" />
          <template v-else>
            <div
              class="one-rank"
              v-for="(item, index) in hourData"
              :key="index"
            >
              <div class="rank">{{ index + 1 }}</div>
              <div class="station-name">{{ item.stationName }}</div>
              <div class="gress-border">
                <div
                  class="progress"
                  :style="{
                    width: `${
                      item.fullHour / 8 >= 1
                        ? '100%'
                        : (item.fullHour / 8).toFixed(2) * 100 + '%'
                    }`,
                  }"
                ></div>
              </div>
              <div class="num">{{ item.fullHour }}h</div>
            </div>
          </template>
        </div>
      </div>
      <div class="layer-one">
        <div class="left-head">
          <div class="title">节能减排</div>
        </div>
        <div class="layer-content pd48 pd30">
          <div class="inner-box">
            <div
              class="magic-box"
              v-for="(item, index) in energyList"
              :key="index"
            >
              <div class="border-box">
                <img :src="item.imgsrc" alt="" class="img-style" />
              </div>
              <div class="magic-num">
                <div class="text">{{ item.title }}</div>
                <div class="num">
                  {{ item.num }} <span>{{ item.unit }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 暂无 -->
    <!-- <div class="layer-box layer-right">
      <div class="layer-one">
        <div class="left-head">
          <div class="title">场站数量统计</div>
        </div>
        <div class="layer-content">
          <div class="status-box">
          </div>
        </div>
      </div>
      <div class="layer-one">
        <div class="left-head left-flex">
          <div class="title">整体计划发电量</div>
        </div>
      </div>
      <div class="layer-one">
        <div class="left-head">
          <div class="title">计划完成情况</div>
        </div>
        <div class="layer-content padding-all">

        </div>
      </div>
    </div> -->
  </div>
</template>
  <script>
import moment from "moment";
import {
  capacityEchartData,
  powerHistoryEchart,
  ringGraphEchart,
} from "./data.js";
import EchartChina from "@/components/EchartChina.vue";
import Echart3D from "@/components/Echart3D.vue";
import { ToDigital } from "@/utils/mapUtils";
import {
  stationInfo,
  stationStatus,
  allPowerHistory,
  dashboardFullHour,
  stationDistribution,
} from "@/api/dataBoard.js";

export default {
  components: {
    EchartChina,
    Echart3D,
  },
  data() {
    return {
      title: "",
      moment,
      stationInfoData: {}, // 顶部数据
      // 电站状态简报
      powerStation: [
        {
          name: "接入中电站",
          value: "1",
          prop: "join",
        },
        {
          name: "全部设备离线电站",
          value: "4",
          prop: "allOffLineCount",
        },
        {
          name: "部分设备离线电站",
          value: "0",
          prop: "partOffLineCount",
        },
        {
          name: "有报警电站",
          value: "0",
          prop: "",
        },
      ],
      totalStation: 0, // 电站总数

      monthHistoryTotal: 0, // 整体发电历史 月总量
      yearHistoryTotal: 0, // 整体发电历史 年总量
      hourData: [], // 满发小时排名前5名

      currentBtn: 1,

      // 节能减排
      energyList: [
        {
          title: "CO₂减排量",
          num: "1",
          unit: "吨",
          imgsrc: require("../../assets/screen/iconfont/wuranqiye.png"),
        },
        {
          title: "节约标准煤",
          num: "1",
          unit: "吨",
          imgsrc: require("../../assets/screen/iconfont/mine.png"),
        },
        {
          title: "等效植树量",
          num: "1",
          unit: "课",
          imgsrc: require("../../assets/screen/iconfont/tudi.png"),
        },
      ],
    };
  },
  mounted() {
    this.title = this.$route.query.title;
    // 初始化echarts
    this.$nextTick(() => {
      this.initChart();
    });
  },
  methods: {
    initChart() {
      this.startInit()
      this.intervalId ? clearInterval ( this.intervalId ) : ''
      this.intervalId = setInterval ( () => {
        this.startInit()
      }, 120000 );
    },
    // 封装给定时任务调用
    startInit(){
      this.getStationInfo (); // 获取顶部nav数据
      this.getAllPowerHistory ( this.currentBtn ); // 整体发电历史 月总量
      this.getAllPowerHistory ( 2 ); // 整体发电历史 年总量
      this.getDashboardFullHour (); // 日满发小时排名
      this.initChinaMap (); // 初始化地图
    },

    // 获取顶部nav数据
    getStationInfo() {
      stationInfo({}).then((res) => {
        this.stationInfoData = res.data;
      });
    },
    // 获取装机容量统计
    getCapacity() {
      // stationStatus 是电站通讯状态简报饼状图接口
      // 装机容量统计暂无接口有的话替换下就好
      stationStatus({}).then((res) => {
        this.powerStation.forEach((item) => {
          for (let k in res.data) {
            if (k == item.prop) {
              item.value = res.data[k];
            }
          }
        });
        this.totalStation = res.data.count;
        var capacity = this.echarts.init(
          document.getElementById("capacity", null, {
            devicePixelRatio: window.devicePixelRatio,
          })
        );
        capacity.setOption(
          capacityEchartData({
            powerStation: this.powerStation,
            totalStation: this.totalStation,
          })
        );
      });
    },
    changeBtn(index) {
      if (this.currentBtn == index) return;
      this.currentBtn = index;
      this.getAllPowerHistory(this.currentBtn);
    },
    // 整体发电历史 timeType 1:月 2:年
    getAllPowerHistory(timeType = 1) {
      let startTime, endTime;
      startTime =
        timeType == 1 ? moment().startOf("month") : moment().startOf("year");
      endTime =
        timeType == 1 ? moment().endOf("month") : moment().endOf("year");
      allPowerHistory({
        timeType,
        startTime: startTime.format("YYYY-MM-DD 00:00:00"),
        endTime: endTime.format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        let xData = [],
          yData = [],
          total = 0;
        res.data.map((item) => {
          xData.push(...Object.keys(item));
          yData.push(...Object.values(item));
          for (let key in item) {
            total += item[key];
          }
        });
        total = total.toFixed(2);
        let legend = timeType == 1 ? "当月发电量" : "当年发电量";

        this.monthHistoryTotal = total;
        // const powerHistoryMonth = this.echarts.init(
        //   document.getElementById("powerHistoryMonth", null, {
        //     devicePixelRatio: window.devicePixelRatio,
        //   })
        // );
        // powerHistoryMonth.setOption(
        //   powerHistoryEchart({ xData, yData }, "kWh", legend, total)
        // );
        if (timeType == 2) {
          this.yearHistoryTotal = total;
          this.getEnergyList(); // 节能减排
        }
      });
    },

    // 日满发小时排名
    getDashboardFullHour() {
      dashboardFullHour({
        createTime: moment().format("YYYY-MM-DD 00:00:00"),
      }).then((res) => {
        this.hourData = res.data.slice(0, 5);
      });
    },

    // 节能减排
    getEnergyList() {
      this.energyList[0].num = (this.yearHistoryTotal * 0.000793).toFixed(2);
      this.energyList[1].num = (this.yearHistoryTotal * 0.000305).toFixed(2);
      this.energyList[2].num = ((this.yearHistoryTotal * 0.997) / 18.3).toFixed(
        2
      );
    },

    // 初始化地图 获取电站分布
    initChinaMap() {
      stationDistribution({}).then((res) => {
        var mapData = [];
        mapData = res.data.map((item) => {
          // item.longitude = item.longitude
          //   ?.replace(/\,/g, "")
          //   .replace(/\./g, "");
          // item.longitude = Number(
          //   `${item.longitude?.slice(0, 3)}.${item.longitude?.slice(3)}`
          // );
          // item.latitude = item.latitude?.replace(/\,/g, "").replace(/\./g, "");
          // item.latitude = Number(
          //   `${item.latitude?.slice(0, 2)}.${item.latitude?.slice(2)}`
          // );
          if (item.longitude && item.latitude) {
            console.log("-----zoumeizou1");
            item.longitude = ToDigital(...item.longitude.split(","));
            item.latitude = ToDigital(...item.latitude.split(","));
            console.log(item.longitude, "item.longitude", item.latitude);
          }
          return {
            name: item.stationName,
            value: [item.longitude, item.latitude],
          };
        });
        this.$nextTick(() => {
          this.$refs.mapChina.initEchartMap(mapData);
          this.$refs.Echart3D.initEchartMap(mapData);
        });
      });
    },
  },
};
</script>
  <style lang="less" scoped>
.screen-box {
  width: 100%;
  height: 100%;
  background: url("../../assets/screen/screen2/bg.svg") center no-repeat;
  background-size: cover;
  position: relative;
  overflow-y: hidden;
  z-index: 1;
  font-size: 20px;
  .mapclass {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 1;
  }
  .screen-head {
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    height: 80px;
    background: url("../../assets/screen/screen2/header-top.png") no-repeat top;
    background-size: contain;
    padding: 0 32px;
    display: flex;
    justify-content: center;
    // align-items: center;
    z-index: 100;
    .head-center {
      flex: 1;
      padding-bottom: 16px;
      align-self: center;
      font-family: PangMenZhengDao, Arial, "sans-serif";
      font-size: 34px;
      color: #d6eeff;
      text-align: center;
      text-shadow: 0 0 16px #d6eeff14;
      font-weight: 700;
    }
    .slide-box {
      width: 30%;
    }
    .head-title {
      text-align: right;
      font-size: 12px;
      color: #ffffff73;
    }
  }
  .mt6 {
    position: relative;
    z-index: 100;
    margin-top: 96px;
    height: 79px;
    width: 100%;
    .flex-box {
      display: flex;
      justify-content: center;
      align-items: center;
      box-sizing: border-box;
      padding: 0 10px;
      gap: 32px;
      li {
        box-shadow: 0 0 64px #097efe33 inset;
        padding-top: 4px;
        padding-left: 32px;
        width: 292px;
        height: 79px;
      }
    }
    .item-top {
      color: #ffffff;
      span:first-child {
        font-size: 26px;
        margin-right: 10px;
        font-weight: bold;
      }
    }
    .item-btm {
      color: #ffffff73;
    }
  }
  .layer-box {
    position: absolute;
    z-index: 100;
    flex-direction: column;
    align-content: space-between;
    justify-content: space-between;
    align-items: center;
    width: 520px;
    margin-top: 32px;

    .left-head {
      padding: 0 16px;
      position: relative;
      height: 42px;
      line-height: 42px;
      background: url("../../assets/screen/screen2/header.svg") top left
        no-repeat;
      background-size: contain;
      align-items: center;
      .two-btn {
        display: flex;
        font-size: 12px;
        div {
          color: #fff;
          background-color: #ffffff1a;
          padding: 0 16px;
          height: 24px;
          line-height: 24px;
          cursor: pointer;
        }
        .left-btn {
          border-top-left-radius: 2px;
          border-bottom-left-radius: 2px;
        }
        .right-btn {
          border-top-right-radius: 2px;
          border-bottom-right-radius: 2px;
        }
        .active {
          background-image: linear-gradient(to right, #048fff, #3ebdff);
        }
      }
      &::before {
        position: absolute;
        top: -4px;
        left: -7px;
        display: block;
        width: 12px;
        height: 12px;
        content: "";
        border: 2px #097efe solid;
        border-radius: 50%;
      }
      .title {
        font-size: 20px;
        font-weight: 700;
        color: #ffffffcc;
      }
      .time {
        font-size: 14px;
        // font-weight: 700;
        color: #ffffffcc;
      }
    }
    .left-flex {
      display: flex;
      justify-content: space-between;
    }
    .layer-content {
      height: 256px;
      .layer-cbox {
        margin-left: 64px;
        padding-top: 48px;
        box-sizing: border-box;
        display: flex;
        #ripple-echarts {
          margin-right: 64px;
          width: 160px;
          height: 160px;
        }
        .right-data {
          .datanum-box {
            .title {
              font-size: 18px;
              color: #989fab;
            }
            .num {
              font-size: 24px;
              color: #fff;
              span {
                font-size: 16px;
              }
            }
          }
          .bp-box {
            padding-bottom: 16px;
            border-bottom: 1px solid rgba(151, 214, 250, 0.15);
          }
        }
      }
      #powerHistoryMonth,
      #powerPlanYear {
        width: 100%;
        height: 240px;
      }
      .status-box {
        padding: 0 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr;
        .one-status {
          height: 62px;
          padding-top: 32px;
          box-sizing: border-box;
          display: flex;
          .status-box_item_pie {
            width: 30px;
            height: 30px;
            padding: 2px;
            margin-right: 10px;
            border: 2px solid transparent;
            border-radius: 50%;
          }
          .status-box_item_content {
            flex: 1;
            margin-right: 60px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            font-size: 18px;
            color: #fff9;
            border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          }
        }
      }
      .status-report {
        padding: 0 20px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: repeat(4, 1fr);
      }
    }
    .padding-all {
      padding: 12px 8px 0 20px;
      box-sizing: border-box;
      .one-rank {
        display: flex;
        align-items: center;
        margin-top: 20px;
        .rank {
          width: 18px;
          height: 18px;
          overflow: hidden;
          font-size: 12px;
          line-height: 18px;
          line-height: 18px;
          text-align: center;
          border-radius: 50%;
          background-color: #097efe73;
          color: #fff;
          opacity: 0.65;
          margin-right: 18px;
        }
        .first-rank {
          background-color: #1bab9e;
          opacity: 1;
        }
        .station-name {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #ffffff73;
          font-size: 16px;
          margin-right: 20px;
          flex: 1;
        }
        .gress-border {
          width: 210px;
          height: 18px;
          padding: 3px;
          box-sizing: border-box;
          border: 1px solid #3ab9ff;
          border-radius: 8px;
          .progress {
            height: 100%;
            width: 0;
            background: linear-gradient(to right, #0a7ffe, #3dbcff);
            border-radius: 4px;
          }
        }
        .num {
          color: #ffffffcc;
          font-size: 16px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          width: 96px;
          padding-left: 12px;
          box-sizing: border-box;
        }
      }
    }
    .pd48 {
      padding-top: 48px;
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      align-items: center;
      .inner-box {
        display: flex;
        width: 100%;
        justify-content: space-between;
        .magic-box {
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          .border-box {
            width: 62px;
            height: 62px;
            position: relative;
            box-sizing: border-box;
            border-radius: 50%;
            background: url("../../assets/screen/screen2/border-img.svg");
            background-size: 100% 100%;
            .img-style {
              width: 40px;
              height: 40px;
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%, -50%);
            }
            &::before {
              content: "";
              inset: -5px;
              border: 1px dashed #3ebdff;
              position: absolute;
              border-radius: 50%;
              animation: roteBorder 6s linear infinite;
            }
          }
          .magic-num {
            margin-top: 16px;
            text-align: center;
            .text {
              font-size: 16px;
              color: #ffffff73;
            }
            .num {
              font-size: 20px;
              color: #fff;
              span {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
    .pd30 {
      padding-top: 20px;
    }
    .padding-box {
      position: relative;
      #radar-echarts {
        width: 200px;
        height: 200px;
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
      .power-survey {
        display: flex;
        align-items: center;
        @keyframes roteBorder {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
        .leida {
          width: 20px;
          height: 20px;
          box-sizing: border-box;
          position: relative;
          border-radius: 50%;
          &::before {
            content: "";
            display: block;
            position: absolute;
            border-radius: 50%;
            inset: -8px;
            animation: roteBorder 6s linear infinite;
          }
        }
        .survey-num {
          margin-left: 14px;
          font-size: 20px;
          color: #fff;
          .num {
            font-weight: 700;
            span {
              font-size: 12px;
              font-weight: 400;
            }
          }
          .title {
            color: #ffffff73;
            font-size: 14px;
          }
        }
      }
      .power-adress1 {
        position: absolute;
        top: 25px;
        left: 15px;
        .leida {
          background: #12d99a73;
          &::before {
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-left: 2px solid #12d99a;
            border-right: 2px solid #12d99a;
          }
        }
      }
      .power-adress2 {
        position: absolute;
        top: 25px;
        right: 15px;
        .leida {
          background: #3cf3fc73;
          &::before {
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-left: 2px solid #3cf3fc;
            border-right: 2px solid #3cf3fc;
          }
        }
      }
      .power-adress3 {
        position: absolute;
        bottom: 25px;
        left: 15px;
        .leida {
          background: #5e58ff73;
          &::before {
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-left: 2px solid #5e58ff;
            border-right: 2px solid #5e58ff;
          }
        }
      }
      .power-adress4 {
        position: absolute;
        bottom: 25px;
        right: 15px;
        .leida {
          background: #097efe73;
          &::before {
            border: 2px solid rgba(255, 255, 255, 0.1);
            border-left: 2px solid #097efe;
            border-right: 2px solid #097efe;
          }
        }
      }
    }
  }
  .layer-left {
    top: 176px;
    bottom: 32px;
    left: 32px;
    position: absolute;
    z-index: 2;
  }
  .layer-right {
    position: absolute;
    z-index: 2;

    top: 176px;
    bottom: 32px;
    right: 32px;
  }
  .layer-center {
    position: relative;
    z-index: 100;
    width: 760px;
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    .one-box {
      width: 340px;
      height: 106px;
      position: relative;
      padding-top: 20px;
      padding-left: 32px;
      .content {
        .title {
          color: #ffffff73;
          font-size: 12px;
        }
        .num {
          font-size: 32px;
          margin-right: 4px;
          font-weight: 700;
          color: #fff;
          span {
            font-size: 16px;
          }
        }
      }
    }
    .typeA {
      box-shadow: 0 0 64px #097efe33 inset;
      .bg-style {
        background: #0d62c3;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .typeB {
      box-shadow: 0 0 64px #f09b6933 inset;
      .bg-style {
        background: #a77e74;
        width: 4px;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
      }
    }
    .btn-box {
      display: flex;
      .one-btn {
        width: 116px;
        height: 24px;
        color: #ffffff73;
        background-color: #ffffff1a;
        font-size: 14px;
        text-align: center;
        line-height: 24px;
        cursor: pointer;
        transition: all 0.5s;
      }
      .left-btn {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      .right-btn {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .choose-btn {
        color: #fff;
        background-image: linear-gradient(to right, #097efe, #3ebdff);
      }
    }
    .bottom-content {
      width: 100%;
      height: 220px;
      display: flex;
      #pie-echarts {
        width: 208px;
        height: 220px;
      }
      .right-lengd {
        flex-grow: 1;
        padding-left: 16px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .lengd-box {
          width: 100%;
          display: flex;
          flex-wrap: wrap;
          .one-lengd {
            display: flex;
            margin: 8px 0;
            margin-right: 32px;
            align-items: center;
            .circle {
              width: 10px;
              height: 10px;
              border-radius: 50%;
            }
            .provice {
              font-size: 12px;
              color: #ffffff73;
              margin-left: 8px;
            }
            .num {
              font-size: 12px;
              color: #fff;
              margin-left: 8px;
            }
            .unit {
              font-size: 12px;
              color: #fff;
              margin-left: 8px;
            }
          }
        }
      }
    }
  }
  .center-top {
    display: flex;
    top: 112px;
    justify-content: space-between;
  }
  .center-bottom {
    bottom: 16px;
  }
}
/deep/.ant-empty-description {
  color: #fff;
}
</style>
